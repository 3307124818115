<template>
  <div class="payment-gateway-transactions">
    <draggable-dynamic-table ref="paymentGatewayTransactions"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :in-modal="true"
                             @load:more="getTransactions()"/>

    <button id="printPaymentGatewayTransactions" v-show="false" @click="handlePrintPaymentGatewayTransactions"></button>
  </div>
</template>

<script>
import axios from "axios";
import {getPaymentGatewayTransactions, printPaymentGatewayTransactions} from '../../../../../../http/requests/paymentGateways'
import {getTimeFromServer, hideLoading, showLoading} from "../../../../../../assets/js/functions";

export default {
  name: 'paymentGatewayTransactions',
  props: {
    paymentGatewayId: 0
  },
  data () {
    return {
      requestSent: false,
      options: {
        id: 'paymentGatewayTransactionsList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'treasury.paymentGateways.transactions.table.header.balance',
          width: '120px',
          minWidth: 120,
          sortable: true
        },
        {
          field: 'price',
          i18n: 'treasury.paymentGateways.transactions.table.header.price',
          width: '120px',
          minWidth: 120,
          sortable: true,
          locked: true
        },
        {
          field: 'description',
          i18n: 'treasury.paymentGateways.transactions.table.header.description',
          width: '130px',
          minWidth: 130,
          locked: true,
          sortable: true
        },
        {
          field: 'date',
          i18n: 'treasury.paymentGateways.transactions.table.header.date',
          align: 'center',
          width: '120px',
          minWidth: 120,
          sortable: true
        },
        {
          field: 'docNumber',
          i18n: 'treasury.paymentGateways.transactions.table.header.docNumber',
          width: '80px',
          minWidth: 80,
          align: 'center',
          locked: true,
          sortable: true,
          footer: {
            type: 'auto-counter'
          }
        }
      ],
      bank: null,
      data: [],
      filters: [],
      sorts: ['order[0]=created_at,desc'],
      page: 1,
      loadingTimer: 0,
      endedList: false,
      actions: [
        {
          leftToolbar: [
            {
              id: 'printTable',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    /*setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)*/

    // this.$vs.loading()
    this.getTransactions()
  },
  methods: {
    getTransactions () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.paymentGatewayTransactions && this.data.length === 0) {
              this.$refs.paymentGatewayTransactions.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.paymentGatewayTransactions && this.data.length > 0) {
              this.$refs.paymentGatewayTransactions.loadMoreStatus = 'Loading'
            }

            getPaymentGatewayTransactions(this.paymentGatewayId, this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0

              const transactions = response.data.data
              transactions.forEach(transaction => {
                this.data.push({
                  docNumber: transaction.document.id,
                  date: transaction.created_at,
                  description: transaction.document.description,
                  price: {
                    type: 'price',
                    classes: transaction.price < 0 ? 'text-danger' : 'text-success',
                    value: Math.abs(transaction.price)
                  },
                  balance: {
                    type: 'price',
                    classes: transaction.balance < 0 ? 'text-danger' : 'text-success',
                    value: Math.abs(transaction.balance)
                  },
                  route: transaction.document.reference_type !== '' ? this.getDocumentReferenceRoute(transaction.document.reference_type, transaction.document.reference) : {}
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('docNumber')

                this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
              }

              if (this.$refs.paymentGatewayTransactions) this.$refs.paymentGatewayTransactions.loadMoreStatus = ''
              this.requestSent = false
            }).catch(() => {
              if (this.$refs.paymentGatewayTransactions) this.$refs.paymentGatewayTransactions.loadMoreStatus = 'Danger'
              this.requestSent = false
            })
          } else {}
          this.requestSent = false
        }, 500)
      }
    },
    getDocumentReferenceRoute (type, reference) {
      const mapper = {
        '1': 'saleInvoice',
        '2': 'purchaseInvoice',
        '3': 'returnSaleInvoice',
        '4': 'returnPurchaseInvoice'
      }
      switch (type) {
      case 'Invoice':
        return {name: mapper[reference.type], params: {id: reference.id}}

      case 'OpeningInventory':
        return {name: 'openingBalance'}

      default:
        return {}
      }
    },

    handlePrintPaymentGatewayTransactions () {
      showLoading()

      printPaymentGatewayTransactions(this.paymentGatewayId, this.filters, this.sorts).then(response => {
        hideLoading()

        const data = response.data

        let time = getTimeFromServer()
        let fileURL = window.URL.createObjectURL(new Blob([data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `paymentGatewayTransactionList-${ time }.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
        .catch((error) => {
          hideLoading()

          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('treasury.paymentGateways.notifications.print.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
    }
  }
}
</script>

<style scoped>
.payment-gateway-transactions {
  height: 100%;
}
</style>
